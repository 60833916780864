<template>
  <div>
    <div class="flex justify-end">
        <div class="w-full sm:w-1/2 mb-5">
          <input
            type="search"
            name="search"
            class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-14 w-full"
            placeholder="Search for user's name etc."
            v-model="searchQuery"
            @keyup.enter="$refs.table.loadAjaxData"
            />
          <ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
        </div>
      </div>
    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable
        :index="true"
        :reverse-index="true"
        :url="`${this.$baseurl}/admin/personal/cards/defaults`"
        :ajax="true"
        :exportable="true"
        :fillable="true"
        :ajaxPagination="true"
        :data="cards"
        :columns="columns"
        :query="searchQuery"
        :onClick="click"
        rangeable="created_at"
        ref="table"
      />
    </div>
  </div>
</template>
<script>
//import moment from "moment";
export default {
    data() {
        return {
          searchQuery: "",
            cards: [],
            loading: false,
            columns: [
                {
                    name: "user",
                    th: "User",
                    render: (card) =>
                        `${card?.account?.user?.name} ${card?.account?.user
                            ?.last_name || ""}`,
                },
                {
                    name: "credit_card_limit",
                    th: "Credit Card Limit",
                    render: (card) => {
                        return `₦ ${this.$options.filters.currency(
                            card.account?.credit_card_limit || 0
                        )}`;
                    },
                },
                {
                    name: "amount",
                    th: "Amount in Default",
                    render: (card) => {
                        return `₦ ${this.$options.filters.currency(
                            card.originating_statement?.total_outstanding || 0
                        )}`;
                    },
                },
                {
                    name: "amount",
                    th: "Default Charge",
                    render: (card) => {
                        return `₦ ${this.$options.filters.currency(
                            card?.amount || 0
                        )}`;
                    },
                },
                {
                    name: "due_date",
                    th: "Default Date",
                    render: (card) => {
                        return this.$moment(card.created_at).format("MMM Do YYYY");
                    },
                },
                {
                    name: "days_default",
                    th: "Days in Default",
                    render: (card) => {
                        // getting the salary date
                        const endDate = card.originating_statement?.end_date
                        let salaryDate = new Date(endDate);
                        salaryDate.setDate(card.account?.user?.profile.salary_day)

                        // getting payment date
                        const paymentDate = card.account?.user?.profile?.payment_date || 7;


                        // getting due date
                        salaryDate.setDate(salaryDate.getDate() + paymentDate)

                        // difference
                        const today = new Date();

                        const a = this.$moment(today)
                        // const b = moment(salaryDate)
                        const b = this.$moment(this.$moment(card.created_at).subtract(1, 'days'));

                        return a.diff(b, 'days') - 1;
                    },
                },
            ],
        };
    },
    beforeMount() {
        this.getDefaulters();
    },
    methods: {
        click(defaultUser) {
            this.$router.push({ name: 'staff-view', params: { staffId: defaultUser.account.user?.id}, });
        },
        async getDefaulters() {
            this.loading = true;
            await this.$get({
                url: `${this.$baseurl}/admin/personal/cards/defaults`,
                headers: this.headers,
                success: (response) => {
                    this.cards = response.data.cards;
                    this.loading = false;
                },
            });
        },
    },
};
</script>
