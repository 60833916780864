<template>
  <div>
    <div class="flex justify-end">
      <div class="w-full sm:w-1/2 mb-5">
        <input
          type="search"
          name="search"
          class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-14 w-full"
          placeholder="Search for user's name etc."
          v-model="searchQuery"
          @keyup.enter="$refs.table.loadAjaxData"
        />
        <ion-icon
          name="search-outline"
          class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
          style="top: 50%; transform: translateY(-50%);"
        ></ion-icon>
      </div>
    </div>
    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable
        :index="true"
        :reverse-index="true"
        :url="`${this.$baseurl}/admin/personal/repayments/defaults`"
        :ajax="true"
        :exportable="true"
        :fillable="true"
        :ajaxPagination="true"
        :data="users"
        :columns="columns"
        :onClick="click"
        :query="searchQuery"
        ref="table"
      />
    </div>
  </div>
</template>
<script>
//import moment from "moment";

export default {
  data(){
    return {
      searchQuery: "",
      company: null,
      loading: false,
      users: [],
      columns: [
        {
          name: "user",
          th: "User",
          render: (user) => `${user?.name} ${user?.last_name || ""}`,
        },
        {
          name: "company",
          th: "Company",
          render: (user) => user?.company?.name,
        },
        {
          name: "total_repayments",
          th: "Repayments Due",
          render: (user) => {
            var count = user.repayments.filter(
              (repayment) => repayment.status == "due" && repayment.defaulted
            ).length;

            return `${count} ${this.$options.filters.toPlural(
              "Repayment",
              count
            )}`;
          },
        },
        {
          name: "amount",
          th: "Amount In Default",
          render: (user) => {
            var amount = user.repayments.reduce((last, current) => {
              return (
                last +
                (current.status == "due" && current.defaulted
                  ? current.amount
                  : 0)
              );
            }, 0);
            return `₦ ${this.$options.filters.currency(amount)}`;
          },
        },
        {
          name: "default_charge",
          th: "Default Charges",
          render: (user) => {
            var amount = user.repayments.reduce((last, current) => {
              return (
                last +
                (current.status == "due" && current.defaulted
                  ? current.default_charge?.amount || 0
                  : 0)
              );
            }, 0);
            return `₦ ${this.$options.filters.currency(amount)}`;
          },
        },
        {
          name: "total_amount",
          th: "Disbursed Amount",
          render: (user) => {
            var amount = user.repayments.reduce((last, current) => {
              return last + current.amount;
            }, 0);
            return `₦ ${this.$options.filters.currency(amount)}`;
          },
        },
        {
          name: "due_date",
          th: "Days in Default",
          render: (user) => {
            var firstRepayment = user.repayments[0];

            return this.$moment(firstRepayment?.due_date).fromNow();
          },
        },
      ],
    };
  },
  beforeMount() {
    //this.getRepayments();
  },
  mounted() {},
  methods: {
    click(user) {
      this.$router.push({
        name: "staff-view",
        params: { staffId: user.id, accountType: this.accountType },
      });
    },
    async getRepayments() {
      this.loading = true;
      await this.sendRequest("admin.repayments.defaults", {
        success: (response) => {
          this.users = response.data.users;
        },
      });
      this.loading = false;
    },
  },
};
</script>