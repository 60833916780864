<template>
  <div>

    <h1 class="text-2xl font-bold mb-5">{{ tab | sentenceCase }} Defaulters</h1>

    <div class="grid grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center">
        <tabs :tabs="tabs" v-model="tab" />
      </div>
    </div>

     <component :is="tab" :query="searchQuery" />
  </div>
</template>
<script>
  export default {
    components: {
      loan: require('./Personal').default,
      creditcard: require('./CreditCard').default,
    },
    data() {
      return {
        searchQuery: '',
        tabs: [
          {name: 'loan', title: 'Loan'},
          {name: 'creditcard', title: 'Credit Card'},
        ],
        tab: 'loan'
      }
    },
  }
</script>